import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import FetchContext from 'og-merchant-portal-react-library/lib/FetchContext';
import { SpinnerLoading, UserInfoContext } from "og-merchant-portal-react-library";
import I18nContext from "../components/common/internationalization/I18nContext";
import StyledLoadingContainer from "./CookieConsentDialogContext.styled";
import AnalyticsContext from "./AnalyticsContext";

const CookieConsentDialogContext = React.createContext({
  showCookieConsentDialog: null,
  setShowCookieConsentDialog: () => { },
  saveCookieConsentMetadata: () => { }
});

const CookieConsentMetadataLocalStorageEntryName = 'cookieConsent-Metadata';

export const CookieConsentDialogProvider = ({ children }) => {
  const { fetcher } = useContext(FetchContext);
  const { analytics } = useContext(AnalyticsContext);
  const { locale } = useContext(I18nContext);
  const { user } = useContext(UserInfoContext);
  const [showCookieConsentDialog, setShowCookieConsentDialog] = React.useState(null);

  const checkIfCookieConsentShouldBeDisplayed = () => {
    const cookieConsentLocalStorageEntry = global.localStorage.getItem(CookieConsentMetadataLocalStorageEntryName);
    const cookieConsentMetadata = JSON.parse(cookieConsentLocalStorageEntry);
    const localeCookieConsentMetadata = cookieConsentMetadata ? cookieConsentMetadata[locale] : null;

    if (!localeCookieConsentMetadata) {
      setShowCookieConsentDialog(true);
      return;
    }

    fetcher
      .get(`/api/rbmp/cookie-consent/metadata-validation?locale=${locale}&metadata=${localeCookieConsentMetadata}`)
      .then(response => {
        setShowCookieConsentDialog(!response.isValid);
        if (response.isValid
          && analytics.isCookieConsentAccepted()
          && user
          && user.userEmail
          && user.userUuid) {
          analytics.setUserInfo(user.userEmail, '', [user.userUuid]);
        }
      })
      .catch(() => {
        setShowCookieConsentDialog(true);
      });
  };

  const saveCookieConsentMetadata = metadata => {
    const cookieConsentMetadata = JSON.parse(global.localStorage.getItem(CookieConsentMetadataLocalStorageEntryName)) ?? {};
    cookieConsentMetadata[locale] = metadata;
    global.localStorage.setItem(CookieConsentMetadataLocalStorageEntryName, JSON.stringify(cookieConsentMetadata));
  };

  useEffect(() => {
    checkIfCookieConsentShouldBeDisplayed();

    // eslint-disable-next-line
  }, [locale, user]);

  if (showCookieConsentDialog === null) {
    return (
      <StyledLoadingContainer>
        <SpinnerLoading />
      </StyledLoadingContainer>
    );
  }

  return (
    <CookieConsentDialogContext.Provider value={{
      showCookieConsentDialog,
      setShowCookieConsentDialog,
      saveCookieConsentMetadata
    }}
    >
      {children}
    </CookieConsentDialogContext.Provider>
  );
};

CookieConsentDialogProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CookieConsentDialogContext;