import Styled from 'styled-components';

export const StyledScrollBox = Styled.div`
  border: 1px solid ${props => props.theme.colors.greyScale.border};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const StyledPanelTitle = Styled.div`
  font-size: ${props => props.theme.font.sizes.h6};
  color: ${props => props.theme.colors.greyScale.title};
  font-weight: ${props => props.theme.font.weights.semibold};
  line-height: normal;

  align-self: center;
`;

export const StyledParagraph = Styled.div`
  font-size: ${props => props.theme.font.sizes.md};
  color: ${props => props.theme.colors.greyScale.body};
  font-weight: ${props => props.theme.font.weights.regular};
  line-height: normal;
`;

export const StyledSubParagraph = Styled.div`
  margin-top: 0.8rem;
  font-size: ${props => props.theme.font.sizes.md};
  color: ${props => props.theme.colors.greyScale.body};
  font-weight: ${props => props.theme.font.weights.regular};
  line-height: normal;
`;

export const StyledSectionTitle = Styled.div`
  margin-top: 0.8rem;
  font-size: ${props => props.theme.font.sizes.subtitle};
  color: ${props => props.theme.colors.greyScale.title};
  font-weight: ${props => props.theme.font.weights.semibold};
`;

export const StyledList = Styled.ul`
  margin: 0;
`;

export const StyledListElement = Styled.li`
  margin-top: 0.8rem;
  font-size: ${props => props.theme.font.sizes.md};
  color: ${props => props.theme.colors.greyScale.body};
  font-weight: ${props => props.theme.font.weights.regular};
`;

export const StyledAnnexTitle = Styled.div`
  font-size: ${props => props.theme.font.sizes.h6};
  color: ${props => props.theme.colors.greyScale.title};
  font-weight: ${props => props.theme.font.weights.regular};
  line-height: normal;

  margin-top: 2.4rem

  align-self: center;
`;

export const StyledTable = Styled.table`
  font-size: ${props => props.theme.font.sizes.md};
  color: ${props => props.theme.colors.greyScale.title};
  font-weight: ${props => props.theme.font.weights.regular};
  line-height: normal;

  margin-top: 0.8rem;

  border-spacing: 0;
  border-radius: ${props => props.theme.borderRadius.box}px;
  border-top: 1px solid ${props => props.theme.colors.greyScale.border};
  border-right: 1px solid ${props => props.theme.colors.greyScale.border};
  border-left: 1px solid ${props => props.theme.colors.greyScale.border};
`;

export const StyledTableHeaders = Styled.thead`
  background: ${props => props.theme.colors.greyScale.background};
`;

export const StyledTableRow = Styled.tr`
`;

export const StyledTableHeader = Styled.th`
  span {
    font-size: ${props => props.theme.font.sizes.md};
    color: ${props => props.theme.colors.greyScale.title};
    font-weight: ${props => props.theme.font.weights.semibold};
    line-height: normal;
 }

  padding: 0.8rem;
  border-bottom: 1px solid ${props => props.theme.colors.greyScale.border};

  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.greyScale.border};
  }
`;

export const StyledTableBody = Styled.tbody`
`;

export const StyledTableCell = Styled.td`
  font-size: ${props => props.theme.font.sizes.md};
  color: ${props => props.theme.colors.greyScale.title};
  font-weight: ${props => props.theme.font.weights.regular};
  line-height: normal;
  padding: 0.4rem;

  border-bottom: 1px solid ${props => props.theme.colors.greyScale.border};
  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.greyScale.border};
  }
`;
