import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  CookieConsent,
  TextV2,
  UserInfoContext
} from 'og-merchant-portal-react-library';
import FetchContext from 'og-merchant-portal-react-library/lib/FetchContext';
import { Button } from '@worldline/gaia';
import CookieConsentDialogContext from '../../../utils/CookieConsentDialogContext';

import CookieNotice from './CookieNotice';
import StyledReadMoreButton from './Consent.styled';
import AnalyticsContext from '../../../utils/AnalyticsContext';

const Consent = ({ intl, locale }) => {
  const { user } = useContext(UserInfoContext);
  const {
    showCookieConsentDialog,
    setShowCookieConsentDialog,
    saveCookieConsentMetadata
  } = useContext(CookieConsentDialogContext);
  const { fetcher } = useContext(FetchContext);
  const { analytics } = useContext(AnalyticsContext);

  const [showNotice, setShowNotice] = useState(false);
  const [cookieNotice, setCookieNotice] = useState(null);
  const [cookieConsentMetadata, setCookieConsentMetadata] = useState(null);

  useEffect(() => {
    if (!showCookieConsentDialog) {
      return;
    }

    fetcher
      .get(`/api/rbmp/cookie-consent?locale=${locale}`)
      .then(response => {
        setCookieNotice(response.cookieNotice);
        setCookieConsentMetadata(response.cookieConsentMetadata);
      })
      .catch(() => {
        setCookieNotice(null);
      });
    // eslint-disable-next-line
  }, [showCookieConsentDialog]);

  const acceptAnalytics = () => {
    analytics.setAcceptTracking();
    analytics.setUserInfo(user.userEmail, '', [user.userUuid]);
    saveCookieConsentMetadata(cookieConsentMetadata);
    setShowCookieConsentDialog(false);
  };

  const declineAnalytics = () => {
    analytics.setDeclineTracking();
    saveCookieConsentMetadata(cookieConsentMetadata);
    setShowCookieConsentDialog(false);
  };

  if (!showCookieConsentDialog) {
    return null;
  }

  const shortNoticeDescription = (
    <div>
      <TextV2 weight="light">
        {intl.formatMessage({
          id: 'consent.message',
          defaultMessage: 'We use Strictly Necessary cookies for this site to function and are always active, and with your permission Performance cookies to enhance your browsing experience and analyze our traffic. By clicking “Accept”, you consent to our use of Performance cookies.'
        })}
      </TextV2>
      <StyledReadMoreButton>
        <Button kind='tertiary' onClick={() => setShowNotice(prevState => !prevState)}>
          {(!showNotice) ?
            intl.formatMessage({
              id: 'consent.readmore',
              defaultMessage: 'Read more'
            })
            : intl.formatMessage({
              id: 'consent.readless',
              defaultMessage: 'Read less'
            })
          }
        </Button>
      </StyledReadMoreButton>
    </div>
  );

  const fullNotice = <CookieNotice cookieNotice={cookieNotice} />;

  return (
    <CookieConsent
      id="cookieConsentId"
      title={intl.formatMessage({
        id: 'consent.title',
        defaultMessage: 'We value your privacy!'
      })}
      acceptButtonText={intl.formatMessage({
        id: 'consent.accept',
        defaultMessage: 'Accept'
      })}
      declineButtonText={intl.formatMessage({
        id: 'consent.decline',
        defaultMessage: 'Decline'
      })}
      shortDescription={shortNoticeDescription}
      fullNotice={fullNotice}
      displayFullNotice={showNotice}
      onAccept={() => acceptAnalytics()}
      onDecline={() => declineAnalytics()}
      open={showCookieConsentDialog}
    />
  );
};

Consent.propTypes = {
  intl: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};

export default injectIntl(Consent);