import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import CookieConsentDialogContext from '../../../utils/CookieConsentDialogContext';

import { StyledFooter, StyledQuickLink, StyledFooterButton } from './StyledFooter';

const Footer = ({ intl, locale, isDrawerExpanded }) => {
  const quickLinks = window.appSettings?.helpMenu ? window.appSettings.helpMenu[locale] : null;
  const { setShowCookieConsentDialog } = useContext(CookieConsentDialogContext);

  return (
    <StyledFooter isDrawerExpanded={isDrawerExpanded} data-test="footer_quicklinks">
      {(quickLinks) ? quickLinks.map(({ text, url, id }) => (
        <StyledQuickLink href={url} target="_blank" rel="noopener noreferrer" key={id} data-test={`footer_quicklinks_${id}`}>
          {text}
        </StyledQuickLink>
      )) : null}
      <StyledFooterButton onClick={() => setShowCookieConsentDialog(true)}>
        {intl.formatMessage({
          id: 'consent.manage',
          defaultMessage: 'Manage Cookies'
        })}
      </StyledFooterButton>
    </StyledFooter>
  );
};

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
  isDrawerExpanded: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Footer);
