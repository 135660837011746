import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStylesInjector } from '@worldline/gaia';

import App from './components/App';
import KeycloakInitializer from './components/KeycloakInitializer';
import I18nProvider from './components/common/internationalization/I18nProvider';
import NotificationProvider from './components/common/NotificationContext/NotificationProvider';
import UserInfoProvider from './components/common/user-info/UserInfoProvider';
import httpClient from './utils/httpClient/httpClient';
import FetchContextProvider from './utils/FetchContextProvider';
import { CookieLogoutTimeoutProvider } from './utils/CookieLogoutTimeoutContext';
import I18nContext from './components/common/internationalization/I18nContext';
import { CookieConsentDialogProvider } from './utils/CookieConsentDialogContext';
import { AnalyticsProvider } from './utils/AnalyticsContext';

const Bootstrap = ({ locale, translatedMessages, availableTranslations, theme, region }) => (
  <ThemeProvider theme={theme}>
    <KeycloakInitializer>
      <I18nProvider
        locale={locale}
        region={region}
        translatedMessages={translatedMessages}
        availableTranslations={availableTranslations}
      >
        <NotificationProvider>
          <AnalyticsProvider>
            <CookieLogoutTimeoutProvider>
              <FetchContextProvider fetcher={httpClient}>
                <UserInfoProvider>
                  <CookieConsentDialogProvider>
                    <BrowserRouter>
                      <I18nContext.Consumer>
                        {({ addTranslations, locale: localeFromContext }) => (
                          <App
                            availableTranslations={availableTranslations}
                            addTranslations={addTranslations}
                            locale={localeFromContext}
                          />
                        )}
                      </I18nContext.Consumer>
                    </BrowserRouter>
                    <GlobalStylesInjector />
                  </CookieConsentDialogProvider>
                </UserInfoProvider>
              </FetchContextProvider>
            </CookieLogoutTimeoutProvider>
          </AnalyticsProvider>
        </NotificationProvider>
      </I18nProvider>
    </KeycloakInitializer>
  </ThemeProvider>
);

Bootstrap.propTypes = {
  locale: PropTypes.string.isRequired,
  translatedMessages: PropTypes.object,
  availableTranslations: PropTypes.arrayOf(PropTypes.shape({
    locale: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
  })),
  theme: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired
};

Bootstrap.defaultProps = {
  translatedMessages: {},
  availableTranslations: ['en']
};

export default Bootstrap;
