import React from "react";
import PropTypes from "prop-types";
import * as analytics from '@worldline/analytics-tool';

const AnalyticsContext = React.createContext({ analytics: null });

export const AnalyticsProvider = ({ children }) => {
  analytics.initialize(
    window.appSettings.analyticsId,
    window.appSettings.analyticsProject
  );

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  /** The children to render */
  children: PropTypes.node.isRequired
};

export default AnalyticsContext;
