import React from 'react';
import PropTypes from 'prop-types';

import { Hyperlink } from '@worldline/gaia';
import { FormattedMessage } from 'react-intl';
import {
  StyledScrollBox,
  StyledPanelTitle,
  StyledSectionTitle,
  StyledParagraph,
  StyledSubParagraph,
  StyledList,
  StyledListElement,
  StyledAnnexTitle,
  StyledTable,
  StyledTableRow,
  StyledTableHeaders,
  StyledTableHeader,
  StyledTableBody,
  StyledTableCell
} from './CookieNotice.styled';

const CookieNotice = ({ cookieNotice }) => {
  return (
    <StyledScrollBox>
      <StyledPanelTitle>
        {cookieNotice.cookieNoticeTitle}
      </StyledPanelTitle>
      <StyledParagraph>
        {cookieNotice.cookieNoticeTitleDescription}
      </StyledParagraph>

      <StyledSectionTitle>
        {cookieNotice.cookieTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.cookieParagraph1}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookieParagraph2}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookieParagraph3}
      </StyledParagraph>

      <StyledSectionTitle>
        {cookieNotice.cookieTypesTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.cookieTypesParagraph1}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookieTypesParagraph2}
      </StyledParagraph>
      <StyledList>
        {cookieNotice.cookieTypes.map(cookieType =>
          <StyledListElement key={cookieType}>{cookieType}</StyledListElement>
        )}
      </StyledList>

      <StyledSectionTitle>
        {cookieNotice.cookiePurposeTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.cookiePurposeParagraph1}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookiePurposeParagraph2}
      </StyledParagraph>

      <StyledSectionTitle>
        {cookieNotice.cookieLifetimeTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.cookieLifetimeParagraph1}
      </StyledParagraph>

      <StyledSectionTitle>
        {cookieNotice.cookieSettingsTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.cookieSettingsParagraph1}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookieSettingsParagraph2}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookieSettingsParagraph3}
      </StyledParagraph>
      <StyledParagraph>
        {cookieNotice.cookieSettingsMoreInformationParagraph}
      </StyledParagraph>
      <StyledList>
        {cookieNotice.cookieSettingsLinks.map(cookieSettingsLink =>
        (<StyledListElement key={cookieSettingsLink.linkText}>
          <Hyperlink href={cookieSettingsLink.link} openInNewTab>{cookieSettingsLink.linkText}
          </Hyperlink>
         </StyledListElement>)
        )}
      </StyledList>

      <StyledSubParagraph>
        {cookieNotice.cookieSettingsMixpanelParagraph}
      </StyledSubParagraph>
      <StyledParagraph>
        <Hyperlink href={cookieNotice.cookieSettingsMixpanelLink} openInNewTab>{cookieNotice.cookieSettingsMixpanelLink}</Hyperlink>
      </StyledParagraph>

      <StyledSubParagraph>
        {cookieNotice.cookieSettingsMoreInformationParagraph}
      </StyledSubParagraph>
      <StyledList>
        {cookieNotice.cookieSettingsMoreInformationLinks.map(cookieSettingsMoreInformationLink =>
        (<StyledListElement>
          <Hyperlink href={cookieSettingsMoreInformationLink} openInNewTab>{cookieSettingsMoreInformationLink}
          </Hyperlink>
         </StyledListElement>)
        )}
      </StyledList>

      <StyledSectionTitle>
        {cookieNotice.contactUsTitle}
      </StyledSectionTitle>
      <StyledSubParagraph>
        <span>{cookieNotice.contactUsParagraph1} </span>
        <Hyperlink href={`mailto:${cookieNotice.contactUsEmail}`}>
          {cookieNotice.contactUsEmail}
        </Hyperlink>
        <span>. {cookieNotice.contactUsParagraph2} </span>
        <Hyperlink href={cookieNotice.contactUsLinks[0].link} openInNewTab>{cookieNotice.contactUsLinks[0].linkText}</Hyperlink>
      </StyledSubParagraph>


      <StyledAnnexTitle>
        {cookieNotice.annexTittle}
      </StyledAnnexTitle>

      <StyledSectionTitle>
        {cookieNotice.cookieListTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.cookieListParagraph}
      </StyledParagraph>

      <StyledSectionTitle>
        {cookieNotice.strictlyNecessaryCookiesTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.strictlyNecessaryCookiesParagraph}
      </StyledParagraph>
      <StyledTable>
        <StyledTableHeaders>
          <StyledTableRow>
            <StyledTableHeader>
              <FormattedMessage id="consent.cookieDomain" defaultMessage="Cookie domain" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.purpose" defaultMessage="Purpose" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.cookies" defaultMessage="Cookies" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.cookiesUsed" defaultMessage="Cookies used" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.lifespan" defaultMessage="Lifespan" />
            </StyledTableHeader>
          </StyledTableRow>
        </StyledTableHeaders>
        <StyledTableBody>
          {cookieNotice.strictlyNecessaryCookies.map(strictlyNecessaryCookie =>
          (<StyledTableRow>
            <StyledTableCell key="strictlyNecessaryCookie-cookieDomain">
              {strictlyNecessaryCookie.cookieDomain}
            </StyledTableCell>
            <StyledTableCell key="strictlyNecessaryCookie-purpose">
              {strictlyNecessaryCookie.purpose}
            </StyledTableCell>
            <StyledTableCell key="strictlyNecessaryCookie-cookies">
              {strictlyNecessaryCookie.cookies}
            </StyledTableCell>
            <StyledTableCell key="strictlyNecessaryCookie-cookiesUsed">
              {strictlyNecessaryCookie.cookiesUsed}
            </StyledTableCell>
            <StyledTableCell key="strictlyNecessaryCookie-lifespan">
              {strictlyNecessaryCookie.lifespan}
            </StyledTableCell>
           </StyledTableRow>)
          )}
        </StyledTableBody>
      </StyledTable>

      <StyledTable>
        <StyledTableHeaders>
          <StyledTableRow>
            <StyledTableHeader>
              <FormattedMessage id="consent.localStorageKey" defaultMessage="Local storage Key" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.purpose" defaultMessage="Purpose" />
            </StyledTableHeader>
          </StyledTableRow>
        </StyledTableHeaders>
        <StyledTableBody>
          {cookieNotice.strictlyNecessaryCookiesLocalStorageEntries.map(strictlyNecessaryCookiesLocalStorageEntry =>
          (<StyledTableRow>
            <StyledTableCell key="strictlyNecessaryCookiesLocalStorageEntry-key">
              {strictlyNecessaryCookiesLocalStorageEntry.key}
            </StyledTableCell>
            <StyledTableCell key="strictlyNecessaryCookiesLocalStorageEntry-purpose">
              {strictlyNecessaryCookiesLocalStorageEntry.purpose}
            </StyledTableCell>
           </StyledTableRow>)
          )}
        </StyledTableBody>
      </StyledTable>

      <StyledSectionTitle>
        {cookieNotice.performanceCookiesTitle}
      </StyledSectionTitle>
      <StyledParagraph>
        {cookieNotice.performanceCookiesParagraph}
      </StyledParagraph>
      <StyledTable>
        <StyledTableHeaders>
          <StyledTableRow>
            <StyledTableHeader>
              <FormattedMessage id="consent.cookieDomain" defaultMessage="Cookie domain" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.purpose" defaultMessage="Purpose" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.cookies" defaultMessage="Cookies" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.cookiesUsed" defaultMessage="Cookies used" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.lifespan" defaultMessage="Lifespan" />
            </StyledTableHeader>
          </StyledTableRow>
        </StyledTableHeaders>
        <StyledTableBody>
          {cookieNotice.performanceCookies.map(performanceCookie =>
          (<StyledTableRow>
            <StyledTableCell key="performanceCookie-cookieDomain">
              {performanceCookie.cookieDomain}
            </StyledTableCell>
            <StyledTableCell key="performanceCookie-purpose">
              {performanceCookie.purpose}
            </StyledTableCell>
            <StyledTableCell key="performanceCookie-cookies">
              {performanceCookie.cookies}
            </StyledTableCell>
            <StyledTableCell key="performanceCookie-cookiesUsed">
              {performanceCookie.cookiesUsed}
            </StyledTableCell>
            <StyledTableCell key="performanceCookie-lifespan">
              {performanceCookie.lifespan}
            </StyledTableCell>
           </StyledTableRow>)
          )}
        </StyledTableBody>
      </StyledTable>

      <StyledTable>
        <StyledTableHeaders>
          <StyledTableRow>
            <StyledTableHeader>
              <FormattedMessage id="consent.localStorageKey" defaultMessage="Local storage Key" />
            </StyledTableHeader>
            <StyledTableHeader>
              <FormattedMessage id="consent.purpose" defaultMessage="Purpose" />
            </StyledTableHeader>
          </StyledTableRow>
        </StyledTableHeaders>
        <StyledTableBody>
          {cookieNotice.performanceCookiesLocalStorageEntries.map(performanceCookiesLocalStorageEntry =>
          (<StyledTableRow>
            <StyledTableCell key="performanceCookiesLocalStorageEntry-key">
              {performanceCookiesLocalStorageEntry.key}
            </StyledTableCell>
            <StyledTableCell key="performanceCookiesLocalStorageEntry-purpose">
              {performanceCookiesLocalStorageEntry.purpose}
            </StyledTableCell>
           </StyledTableRow>)
          )}
        </StyledTableBody>
      </StyledTable>

    </StyledScrollBox>
  );
};

CookieNotice.propTypes = {
  cookieNotice: PropTypes.shape({
    cookieNoticeTitle: PropTypes.string.isRequired,
    cookieNoticeTitleDescription: PropTypes.string.isRequired,
    cookieTitle: PropTypes.string.isRequired,
    cookieParagraph1: PropTypes.string.isRequired,
    cookieParagraph2: PropTypes.string.isRequired,
    cookieParagraph3: PropTypes.string.isRequired,
    cookieTypesTitle: PropTypes.string.isRequired,
    cookieTypesParagraph1: PropTypes.string.isRequired,
    cookieTypesParagraph2: PropTypes.string.isRequired,
    cookieTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    cookiePurposeTitle: PropTypes.string.isRequired,
    cookiePurposeParagraph1: PropTypes.string.isRequired,
    cookiePurposeParagraph2: PropTypes.string.isRequired,
    cookieLifetimeTitle: PropTypes.string.isRequired,
    cookieLifetimeParagraph1: PropTypes.string.isRequired,
    cookieSettingsTitle: PropTypes.string.isRequired,
    cookieSettingsParagraph1: PropTypes.string.isRequired,
    cookieSettingsParagraph2: PropTypes.string.isRequired,
    cookieSettingsParagraph3: PropTypes.string.isRequired,
    cookieSettingsLinks: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    })).isRequired,
    cookieSettingsMixpanelParagraph: PropTypes.string.isRequired,
    cookieSettingsMixpanelLink: PropTypes.string.isRequired,
    cookieSettingsMoreInformationParagraph: PropTypes.string.isRequired,
    cookieSettingsMoreInformationLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    contactUsTitle: PropTypes.string.isRequired,
    contactUsParagraph1: PropTypes.string.isRequired,
    contactUsEmail: PropTypes.string.isRequired,
    contactUsParagraph2: PropTypes.string.isRequired,
    contactUsLinks: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    })).isRequired,
    annexTittle: PropTypes.string.isRequired,
    cookieListTitle: PropTypes.string.isRequired,
    cookieListParagraph: PropTypes.string.isRequired,
    strictlyNecessaryCookiesTitle: PropTypes.string.isRequired,
    strictlyNecessaryCookiesParagraph: PropTypes.string.isRequired,
    strictlyNecessaryCookies: PropTypes.arrayOf(PropTypes.shape({
      cookieDomain: PropTypes.string.isRequired,
      purpose: PropTypes.string.isRequired,
      cookies: PropTypes.string.isRequired,
      cookiesUsed: PropTypes.string.isRequired,
      lifespan: PropTypes.string.isRequired,
    })).isRequired,
    strictlyNecessaryCookiesLocalStorageEntries: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      purpose: PropTypes.string.isRequired,
    })).isRequired,
    performanceCookiesTitle: PropTypes.string.isRequired,
    performanceCookiesParagraph: PropTypes.string.isRequired,
    performanceCookies: PropTypes.arrayOf(PropTypes.shape({
      cookieDomain: PropTypes.string.isRequired,
      purpose: PropTypes.string.isRequired,
      cookies: PropTypes.string.isRequired,
      cookiesUsed: PropTypes.string.isRequired,
      lifespan: PropTypes.string.isRequired,
    })).isRequired,
    performanceCookiesLocalStorageEntries: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      purpose: PropTypes.string.isRequired,
    })).isRequired
  })
};

export default CookieNotice;